import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import ErrorPage from "./views/errorPage/errorBoundary";
import { icons } from "./assets/icons";
import "./assets/css/generale.css";
import { Provider } from "react-redux";
import store from "./store/index";
import "@assets/main.css";
import "@assets/tailwindcss/tailwind.css";

React.icons = icons;

ReactDOM.render(
  <ErrorPage>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorPage>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
