import { createStore } from 'redux'

const initialState = {
    standardNotification: {},
    sidebarShow: 'responsive',
    dashboard:{
      data:{},
      isLoaded:false
    },
    // user_saas:{
    //   list:[],
    //   isLoaded:false
    // },
    // user_permissions:{
    //   list:[],
    //   isLoaded:false
    // },
    list: {
      enterprises: [],
      enterpriseTest:[],
      clients:[],
      isLoaded:false
    },
    // dayOff:{
    //   list:[],
    //   isLoaded:false
    // },
    errors:{
      notSolved:[],
      done:[],
      isLoaded:false
    },
    externalLinks:{
      list:[],
      attributions:[],
      isLoaded:false
    },
    jobboards:{
      list:[],
      isLoaded:false
    }
}
const reducer = (state = initialState, { payload ,type, ...rest }) => {
  switch (type) {
    case "STANDARD_NOTIFICATION":
        return Object.assign(state, {
          standardNotification: payload,
        });
    case "SET_ENTERPRISE":{
      return Object.assign(state,{
        list: payload
      })
    }
    // case "SET_DAYOFF":{
    //   return Object.assign(state,{
    //     dayOff: payload
    //   })
    // }
    case "SET_ERRORS":{
      return Object.assign(state,{
        errors: payload
      })
    }
    case "SET_EXTERNAL_LINKS":{
      return Object.assign(state,{
        externalLinks: payload
      })
    }
    case "SET_JOBBOARDS":{
      return Object.assign(state,{
        jobboards: payload
      })
    }
    case "SET_DASHBOARD_DATA":{
      return Object.assign(state,{
        dashboard: payload
      })
    }
    case "RESET":{
      return initialState
    }
    case 'set':
            return {...state, ...rest }
    
    default : return state
  }
}

const store = createStore(reducer);

export default store; 